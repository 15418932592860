<template>
	<div class="indicator">
		<el-row type="flex" justify="center" class="row-bg2">
			<el-dialog align="left" title="任务操作详细" :visible.sync="dialogVisible" width="50%">
				<span class="text-wrapper">{{jobRunDetail}}</span>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
				</span>
			</el-dialog>
			<el-col v-bind:span="isMobile ? 24 : 18">
				<el-container>
					<el-header>
						<h2>Job Manager</h2>
					</el-header>
					<el-container>
						<el-main>
							<el-table stripe :data="tableData" style="width: 100%" :row-class-name="tableRowClassName">
								<el-table-column fixed="left" prop="name" label="name"></el-table-column>
								<el-table-column fixed="left" prop="status" label="status">
									<template slot-scope="scope">
										<el-tag
											:type="scope.row.status === 'run' ? 'success' : scope.row.status === 'init' ? 'primary' : 'danger'"
											disable-transitions>{{scope.row.status}}</el-tag>
									</template>
								</el-table-column>
								<el-table-column prop="superviser_pid" label="superviser_pid"></el-table-column>
								<el-table-column prop="module_pid" label="module_pid"></el-table-column>
								<el-table-column prop="start_time" label="start_time"></el-table-column>
								<el-table-column prop="stop_time" label="stop_time"></el-table-column>
								<el-table-column label="operation">
									<template slot-scope="scope">
										<el-button v-bind:disabled="scope.row.status === 'run'"
											@click="operateJob(scope.row.name, 'start')" type="text" size="small">启动
										</el-button>
										<el-button v-bind:disabled="scope.row.status !== 'run'"
											@click="operateJob(scope.row.name, 'stop')" type="text" size="small">停止
										</el-button>
										<el-button v-bind:disabled="scope.row.status !== 'run'"
											@click="operateJob(scope.row.name, 'reload')" type="text" size="small">
											重启
										</el-button>
									</template>
								</el-table-column>
							</el-table>
						</el-main>
					</el-container>
				</el-container>
			</el-col>
		</el-row>
	</div>
</template>

<style>
	/* .el-table .stop-row {
		background: #dc8a96;
	}

	.el-table .run-row {
		background: #a7e1a6;
	}

	.el-table .init-row {
		background: #edd396;
	} */

	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.el-col {
		border-radius: 4px;
	}

	.bg-purple-dark {
		background: #99a9bf;
	}

	.bg-purple {
		background: #d3dce6;
	}

	.bg-purple-light {
		background: #e5e9f2;
	}

	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}

	.row-bg {
		padding: 10px 0;
		background-color: #f9fafc;
	}

	.text-wrapper {
		white-space: pre-wrap;
	}
</style>

<script>
	export default {
		created() {
			this.httpGetJobList()
		},
		mounted() {
			this.isMobile = this._isMobile()
		},
		methods: {
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag;
			},
			tableRowClassName({
				row,
				/*rowIndex*/
			}) {
				// console.log(row, rowIndex)
				if (row.status == 'stop') {
					return 'stop-row';
				} else if (row.status == 'run') {
					return 'run-row';
				}
				return 'init-row'
			},
			operateJob(job_name, operate) {
				console.log(job_name, operate);
				this.$http
					.post('/job/' + job_name + '/' + operate)
					.then(response => {
						console.log(response.data.data)
						this.httpGetJobList()
						this.jobRunDetail = response.data.data
						this.dialogVisible = true
					})
					.catch(function(error) {
						console.log(error);
						this.httpGetJobList()
					});
			},
			// 请求任务列表
			httpGetJobList() {
				this.$http
					.get('/job/list')
					.then(response => {
						this.tableData = response.data.data
						console.log(response.data.data)
					})
					.catch(function(error) {
						console.log(error);
					});
			},
		},
		data() {
			return {
				tableData: [],
				jobRunDetail: "",
				dialogVisible: false,
				isMobile: false,
			}
		}
	}
</script>
